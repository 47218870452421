import camelcaseKeys from 'camelcase-keys';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PaymentMethod } from '@stripe/stripe-js';

import { Price, Subscription } from '~/types';
import { MonthlyShipments, monthlyShipmentsSchema } from '~/types/admin';

export const joylineApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api/' }),
  tagTypes: ['Prices', 'Subscription', 'PaymentMethod', 'MonthlyShipments'],
  reducerPath: 'joylineApi',
  endpoints: (builder) => ({
    getPrices: builder.query<Price[], void>({
      providesTags: ['Prices'],
      query: () => `/get-prices`,
    }),
    getSubscription: builder.query<Subscription, void>({
      providesTags: ['Subscription'],
      query: () => `/get-subscription`,
    }),
    getPaymentMethod: builder.query<PaymentMethod, void>({
      providesTags: ['PaymentMethod'],
      query: () => `/get-payment-method`,
    }),
    getMonthlyShipments: builder.query<MonthlyShipments, { month: number; year: number }>({
      providesTags: ['MonthlyShipments'],
      query: ({ month, year }) => `/get-monthly-shipments?month=${month}&year=${year}`,
      transformResponse: (response: unknown) => {
        const transformedResponse = camelcaseKeys(response as any, { deep: true });

        // Validate unknown data
        monthlyShipmentsSchema.parse(transformedResponse);

        return transformedResponse as MonthlyShipments;
      },
    }),
  }),
});

export const { useGetPricesQuery, useGetSubscriptionQuery, useGetPaymentMethodQuery, useGetMonthlyShipmentsQuery } =
  joylineApi;
