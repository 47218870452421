import * as z from 'zod';

export const recipientSchema = z.object({
  id: z.string(),
  name: z.string().min(1),
  address1: z.string().min(1),
  address2: z.string().optional(),
  city: z.string().min(1),
  state: z.string().min(1),
  zipCode: z.string().min(1),
  active: z.boolean(),
  createdAt: z.string().optional(),
});

export const recipientsSchema = z.array(recipientSchema);

export type Recipient = z.infer<typeof recipientSchema>;

export type Recipients = z.infer<typeof recipientsSchema>;
