import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type RecipientCountState = {
  recipientCount: number;
};

const initialState: RecipientCountState = {
  recipientCount: 1,
};

export const recipientCountSlice = createSlice({
  name: 'recipientCount',
  initialState,
  reducers: {
    updateRecipientCount: (state: RecipientCountState, action: PayloadAction<number>) => {
      state.recipientCount = action.payload;
    },
  },
});

export const { updateRecipientCount } = recipientCountSlice.actions;

export default recipientCountSlice.reducer;
