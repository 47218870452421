import tinycolor from 'tinycolor2';

import { keyframes } from '@chakra-ui/react';
import type { SystemStyleFunction } from '@chakra-ui/theme-tools';

const move = keyframes`
  from{ background-position-x: 0 }
  to{  background-position-x: 10000px }
`;

const baseStyle = {
  borderRadius: 'md',
  _disabled: {
    _hover: {
      shadow: 'none',
    },
  },
};

const variantGhost: SystemStyleFunction = (props) => {
  const { colorScheme, theme } = props;

  const color = colorScheme ? theme.colors[colorScheme] : theme.colors.green;

  return {
    color: color,
    transition: 'all .2s linear',
    _hover: {
      textDecor: 'underline',
    },
  };
};

const variantOutline: SystemStyleFunction = (props) => {
  const { colorScheme, theme } = props;

  const color = colorScheme ? theme.colors[colorScheme] : theme.colors.green;

  const colorIsLight = tinycolor(color).isLight();

  return {
    color: color,
    border: '2px',
    borderColor: color,
    transition: 'all .2s ease-out',
    _hover: {
      bg: color,
      color: colorIsLight && colorScheme !== 'green' ? 'medium-gray' : 'white',
      shadow: 'glow',
    },
  };
};

const variantSolid: SystemStyleFunction = (props) => {
  const { colorScheme, theme } = props;

  const color = colorScheme ? theme.colors[colorScheme] : theme.colors.green;
  const colorIsLight = tinycolor(color).isLight();
  const toColor = colorIsLight && colorScheme !== 'green' ? tinycolor(color).darken() : tinycolor(color).lighten();

  return {
    bg: `radial-gradient(${color}, ${toColor})`,
    color: 'white',
    transition: 'all .2s ease-out',
    animation: `${move} infinite 150s linear`,
    shadow: 'none',
    _hover: {
      shadow: 'glow',
    },
  };
};

const variantSubtle: SystemStyleFunction = (props) => {
  const { theme } = props;

  const color = theme.colors['light-blue'];
  const toColor = tinycolor(color).darken();

  return {
    bg: `radial-gradient(${color}, ${toColor})`,
    color: 'medium-gray',
    transition: 'all .2s ease-out',
    shadow: 'none',
    animation: `${move} infinite 150s linear`,
    _hover: {
      shadow: 'glow',
    },
  };
};

export const Button = {
  baseStyle,
  variants: {
    solid: variantSolid,
    outline: variantOutline,
    ghost: variantGhost,
    subtle: variantSubtle,
  },
  sizes: {
    sm: {
      fontWeight: 'semibold',
    },
    md: {
      fontSize: 'sm',
      fontWeight: 'semibold',
      px: 4,
      py: 3,
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'green',
  },
};
