import * as z from 'zod';

export const cardBrandSchema = z.union([
  z.literal('amex'),
  z.literal('diners'),
  z.literal('discover'),
  z.literal('jcb'),
  z.literal('mastercard'),
  z.literal('unionpay'),
  z.literal('visa'),
  z.literal('unknown'),
]);

export type CardBrand = z.infer<typeof cardBrandSchema>;
