export const styles = {
  global: {
    html: {
      overflowX: 'hidden',
      scrollbarGutter: 'stable',
    },
    body: {
      color: 'medium-gray',
      overflowX: 'hidden',
    },
  },
};
