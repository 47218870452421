import * as z from 'zod';

import { recipientSchema } from './recipient';

export const monthlyShipmentSchema = z.object({
  userId: z.string(),
  photos: z.array(
    z.object({
      path: z.string(),
      url: z.string(),
    })
  ),
  recipients: z.array(recipientSchema),
});

export const monthlyShipmentsSchema = z.array(monthlyShipmentSchema);

export type MonthlyShipments = z.infer<typeof monthlyShipmentsSchema>;

export type MonthlyShipment = z.infer<typeof monthlyShipmentSchema>;
