import { CaptureConsole } from '@sentry/integrations';
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://52f4125b41e6488380761969b82bc888@o1325819.ingest.sentry.io/6585259',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  integrations: [new CaptureConsole()],
});
