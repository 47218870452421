export const Radio = {
  baseStyle: {
    container: {
      '.chakra-radio__control[data-checked]::before': {
        w: 3,
        h: 3,
        boxShadow: `0px 0px 0px 2px white`,
        bgColor: 'green',
      },
    },
    control: {
      bgColor: 'white',
      borderColor: 'light-gray',
      _checked: {
        bgColor: 'green',
        borderColor: 'light-gray',
      },
    },
  },
  sizes: {
    md: {
      control: {
        w: 5,
        h: 5,
      },
    },
  },
};
