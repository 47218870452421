import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  overlay: {
    bg: '#091D2340',
    zIndex: 'modal',
    backdropFilter: 'auto',
    backdropBlur: '2px',
  },
  header: { ...props.theme.components.Heading.sizes.h2, p: { base: 4, md: 8 } },
  body: { p: { base: 4, md: 8 } },
  footer: {
    p: { base: 4, md: 8 },
    position: 'sticky',
    bottom: 0,
    bgColor: 'white',
    borderBottomRadius: 'xl',
    borderTop: '2px',
    borderColor: 'light-blue',
  },
  dialog: {
    borderRadius: 'xl',
    m: 4,
  },
});

export const Modal = {
  baseStyle: baseStyle,
};
