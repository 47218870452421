export const Text = {
  defaultProps: {
    size: 'base',
  },
  sizes: {
    'home.body': {
      fontSize: 'lg',
    },
    base: {
      fontSize: 'md',
    },
    info: {
      fontSize: 'sm',
    },
  },
};
