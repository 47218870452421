export const Heading = {
  sizes: {
    'home.h1': {
      fontSize: { base: '3.5xl', lg: '5xl' },
      fontWeight: 'bold',
      lineHeight: 'shorter',
      color: 'gray',
    },
    'home.h2': {
      fontSize: { base: '3xl', lg: '3.5xl' },
      fontWeight: 'bold',
      color: 'gray',
    },
    h1: {
      fontSize: { base: '3xl', lg: '4xl' },
      fontWeight: 'bold',
      lineHeight: 'shorter',
      color: 'gray',
    },
    h2: {
      fontSize: { base: '2xl', lg: '3xl' },
      fontWeight: 'bold',
      color: 'gray',
    },
    h3: {
      fontSize: { base: 'xl', lg: '2xl' },
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 'lg',
      fontWeight: 'semibold',
    },
  },
};
