import { FC } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';

import { Alert, AlertIcon, ChakraProvider, Text } from '@chakra-ui/react';

import { UserSessionProvider } from '~/features/user-session';
import { store } from '~/store';
import { theme } from '~/theme';

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <Provider store={store}>
        <UserSessionProvider>
          <ChakraProvider theme={theme}>
            {process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' && (
              <Alert variant='subtle' status='warning'>
                <AlertIcon />
                <Text size='info'>This is a dev environment. Use 424242424242424242 for test credit card number.</Text>
              </Alert>
            )}
            <Component {...pageProps} />
          </ChakraProvider>
        </UserSessionProvider>
      </Provider>
    </>
  );
};

export default MyApp;
