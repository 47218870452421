export const colors = {
  green: '#00E175',
  orange: '#FB8250',
  yellow: '#FEFCEA',
  gray: '#323232',
  blue: '#73CDFF',
  'medium-gray': '#595959',
  'light-green': '#E9FEF4',
  'light-blue': '#E9F1F9',
  'light-orange': '#F9EDE8',
  'light-gray': '#C9D6D6',
  'lighter-gray': '#FAFAFA',
};
