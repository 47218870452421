import * as z from 'zod';

export const photoSchema = z.object({
  createdAt: z.string(),
  id: z.string(),
  lastAccessedAt: z.string(),
  metadata: z.object({
    size: z.number(),
    mimetype: z.string(),
    cacheControl: z.string(),
  }),
  name: z.string(),
  updatedAt: z.string(),
  imageUrl: z.string(),
});

export const photosSchema = z.array(photoSchema);

export type Photo = z.infer<typeof photoSchema>;

export type Photos = z.infer<typeof photosSchema>;
