import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { recipientCountSlice } from './features/recipient-count/slice';
import { joylineApi } from './services/joyline';
import { supabaseApi } from './services/supabase';

export const store = configureStore({
  reducer: {
    recipientCount: recipientCountSlice.reducer,
    // userSession: userSessionSlice.reducer,
    [supabaseApi.reducerPath]: supabaseApi.reducer,
    [joylineApi.reducerPath]: joylineApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(supabaseApi.middleware).concat(joylineApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
