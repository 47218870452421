import { FC, ReactNode, useEffect } from 'react';

import { supabase } from '~/libs/supabase';

import { setAuthCookie } from './utils';

export const UserSessionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Listen to auth changes
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      await setAuthCookie(session, event);
    });

    return () => {
      authListener?.unsubscribe();
    };
  }, []);

  return <>{children}</>;
};
