import { Alert } from './alert';
import { Button } from './button';
import { FormLabel } from './form-label';
import { Heading } from './heading';
import { Input } from './input';
import { Link } from './link';
import { Modal } from './modal';
import { Radio } from './radio';
import { Select } from './select';
import { Slider } from './slider';
import { Text } from './text';

export const components = { Alert, Button, FormLabel, Heading, Input, Link, Modal, Radio, Select, Slider, Text };
