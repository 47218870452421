export const Link = {
  baseStyle: {
    color: 'green',
    textDecor: 'underline',
  },
  variants: {
    nav: {
      fontSize: 'sm',
      fontWeight: 'semibold',
      textDecor: 'none',
      color: 'medium-gray',
      _hover: {
        textDecor: 'underline',
      },
    },
  },
};
