import camelcaseKeys from 'camelcase-keys';

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

import { supabase } from '~/libs/supabase';
import {
  Photo,
  Photos,
  photosSchema,
  Recipients,
  recipientsSchema,
  ScheduledPhotos,
  scheduledPhotosSchema,
} from '~/types';

export const supabaseApi = createApi({
  baseQuery: fakeBaseQuery(),
  reducerPath: 'supabaseApi',
  tagTypes: ['Recipients', 'Photos', 'ScheduledPhotos', 'PaymentMethod'],
  endpoints: (builder) => ({
    getRecipients: builder.query<Recipients, void>({
      providesTags: ['Recipients'],
      queryFn: async () => {
        const { data: maybeRecipients, error } = await supabase
          .from('recipients')
          .select('*')
          .order('created_at', { ascending: true });

        if (error) {
          console.error(error);
          return { error };
        }

        // Validate fetched data
        const maybeRecipientsTransformed = camelcaseKeys(maybeRecipients);

        try {
          recipientsSchema.parse(maybeRecipientsTransformed);
        } catch (error) {
          return { error };
        }

        return {
          data: maybeRecipientsTransformed as Recipients,
        };
      },
    }),
    getPhotos: builder.query<Photos, string>({
      providesTags: ['Photos'],
      queryFn: async (userId) => {
        const { data: files, error } = await supabase.storage.from('photos').list(userId, {
          limit: 1000,
          offset: 0,
          sortBy: { column: 'created_at', order: 'asc' },
        });

        if (error) {
          console.error(error);
          return { error };
        }

        if (!files) {
          return { error: `No data returned from supabase.storage.from('photos')` };
        }

        const filesTransformed = camelcaseKeys(files);

        const filePaths = filesTransformed.map((file) => `${userId}/${file.name}`);

        const { data: signedUrls, error: signedUrlsError } = await supabase.storage
          .from('photos')
          .createSignedUrls(filePaths, 60);

        if (signedUrlsError) {
          console.error(error);
          return { error };
        }

        const maybePhotos = filesTransformed.map((file) => {
          const filePath = `${userId}/${file.name}`;
          const imageUrl = signedUrls?.find((signedUrl) => signedUrl.path === filePath)?.signedURL;

          return {
            // @ts-expect-error
            ...(file as Photo),
            imageUrl,
          };
        });

        // Validate photos
        try {
          photosSchema.parse(maybePhotos);
        } catch (error) {
          return { error };
        }

        return {
          data: maybePhotos as Photos,
        };
      },
    }),
    getScheduledPhotos: builder.query<ScheduledPhotos, void>({
      providesTags: ['ScheduledPhotos'],
      queryFn: async () => {
        const { data: maybeScheduledPhotos, error } = await supabase
          .from('scheduled_photos')
          .select('*')
          .order('created_at', { ascending: true });

        if (error) {
          console.error(error);
          return { error };
        }

        // Validate fetched data
        const maybeScheduledPhotosTransformed = camelcaseKeys(maybeScheduledPhotos);

        try {
          scheduledPhotosSchema.parse(maybeScheduledPhotosTransformed);
        } catch (error) {
          return { error };
        }

        return {
          data: maybeScheduledPhotosTransformed as ScheduledPhotos,
        };
      },
    }),
  }),
});

export const { useGetRecipientsQuery, useGetPhotosQuery, useGetScheduledPhotosQuery } = supabaseApi;
