import { sliderAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { mode, orient } from '@chakra-ui/theme-tools';

const baseStyleTrack: SystemStyleFunction = (props) => {
  return {
    borderRadius: 'lg',
    bg: mode('light-blue', 'whiteAlpha.200')(props),
    _disabled: {
      bg: mode('light-blue', 'whiteAlpha.300')(props),
    },
  };
};

const baseStyleThumb: SystemStyleFunction = (props) => {
  return {
    bgColor: 'green',
    border: 'solid 4px',
    borderColor: 'white',
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.16), 0 1px 2px 1px rgba(0, 0, 0, 0.16)',
  };
};

const baseStyleFilledTrack: SystemStyleFunction = (props) => {
  return {
    bgColor: 'green',
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props),
  filledTrack: baseStyleFilledTrack(props),
});

const sizeLg: PartsStyleFunction<typeof parts> = (props) => {
  return {
    thumb: { w: '32px', h: '32px' },
    track: orient({
      orientation: props.orientation,
      horizontal: { h: '12px' },
      vertical: { w: '12px' },
    }),
  };
};

const sizes = {
  lg: sizeLg,
};

const defaultProps = {
  size: 'lg',
};

export const Slider = {
  parts: parts.keys,
  sizes,
  baseStyle,
  defaultProps,
};
