export const Input = {
  variants: {
    outline: {
      field: {
        bgColor: 'lighter-gray',
        borderColor: 'light-gray',
      },
    },
  },
};
