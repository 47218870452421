import * as z from 'zod';

export const scheduledPhotoSchema = z.object({
  id: z.string(),
  createdAt: z.string().optional(),
  userId: z.string(),
  photoName: z.string(),
  month: z.number(),
  year: z.number(),
});

export const scheduledPhotosSchema = z.array(scheduledPhotoSchema);

export type ScheduledPhoto = z.infer<typeof scheduledPhotoSchema>;

export type ScheduledPhotos = z.infer<typeof scheduledPhotosSchema>;
